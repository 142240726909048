<template>
  <div
    class="modal fade"
    id="ModalChangeFeature"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalChangeFeatureLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="d-flex justify-content-between p-3">
          <h5 class="modal-title" id="modalUpdateDiseaseLabel">
            Cài đặt tính năng cho phép
          </h5>
          <button
            type="button"
            class="close bg-white border-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="robo-18-500">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p class="fs-16 fw-500">
            {{ $t("workspace_setting.lbl_workspace")[$orgModel] }}:
            {{ clinic && clinic.name }}
          </p>
          <div class="flex flex-wrap gap-3">
            <div
              v-for="(f, i) in features"
              :key="i + 'feat'"
              class="mt-2"
              style="width: 45%"
            >
              <label
                :for="`invitews-${i}`"
                class="fs-16 txt-grey-900 cursor-pointer mr-3"
              >
                <input
                  type="checkbox"
                  name
                  :id="`invitews-${i}`"
                  v-model="f.value"
                  :disabled="loading"
                />
                {{ f.name }}
              </label>
              <div v-if="f.updated_by">
                <span>Cập nhật lần cuối vào</span>
                <span
                  ><strong class="text-black ml-1">{{
                    getDate(f)
                  }}</strong></span
                >
                <span class="mx-1">bởi</span>
                <span
                  ><strong class="text-black">{{
                    getUpdatedBy(f)
                  }}</strong></span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn bg-pri text-white"
            :disabled="loading"
            @click="submit"
          >
            <span class="spinner-border" role="status" v-if="loading">
              <span class="sr-only">Loading...</span>
            </span>
            <span v-if="!loading">Lưu</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const constFeatures = [
  { name: 'Bệnh án điện tử', key: 'badt', value: false, type: 'boolean' },
  { name: 'Sổ Khám Bệnh', key: 'skb', value: false, type: 'boolean' },
  {
    name: 'Tiếp đón bệnh nhân',
    key: 'reception',
    value: false,
    type: 'boolean'
  },
  { name: 'Khám tư vấn', key: 'examination', value: false, type: 'boolean' },
  { name: 'Lịch làm việc', key: 'schedule', value: false, type: 'boolean' },
  { name: 'Hồ sơ sức khoẻ', key: 'hssk', value: false, type: 'boolean' },
  {
    name: 'Hồ sơ sức khoẻ tổng quát',
    key: 'hsskOverview',
    value: false,
    type: 'boolean'
  },
  {
    name: 'Phòng/Giường điều trị',
    key: 'bedmgnt',
    value: false,
    type: 'boolean'
  },
  { name: 'Hội thảo', key: 'conference', value: false, type: 'boolean' },
  { name: 'Chợ nội bộ', key: 'ecomMk', value: false, type: 'boolean' },
  {
    name: 'Quản lý chợ nội bộ',
    key: 'ecomWarehouse',
    value: false,
    type: 'boolean'
  },
  { name: 'Thu ngân', key: 'payment', value: false, type: 'boolean' },
  {
    name: 'Dịch vụ TMDT phòng khám',
    key: 'examEcom',
    value: false,
    type: 'boolean'
  },
  { name: 'Uptodate', key: 'utd', value: false, type: 'boolean' },
  { name: 'Uptodate Origin', key: 'utd_origin', value: false, type: 'boolean' }
]
export default {
  name: 'ModalChangeFeature',
  props: ['clinic'],
  data () {
    return {
      features: [...constFeatures],
      loading: false
    }
  },
  mounted () {
    let self = this
    window.$(document).ready(function () {
      window.$('#ModalChangeFeature').on('shown.bs.modal', function (e) {
        // do something...
        self.loading = true
        if (self.clinic) {
          self.getCurrent()
        } else {
          self.loading = false
        }
      })
      window.$('#ModalChangeFeature').on('hidden.bs.modal', function (e) {
        // do something...
        self.features = constFeatures
        self.loading = true
      })
    })
  },
  methods: {
    async getCurrent () {
      let params = {
        ws_id: this.clinic?.id
      }
      this.loading = true
      this.$rf
        .getRequest('AdminRequest')
        .getWorkspaceSettings(params)
        .then((r) => {
          if (r.data) {
            this.features = this.features.map((item) => {
              const dataInfo = r.data?.find(
                (findItem) => findItem.key === item.key
              )
              item.value = dataInfo?.value === 'true'
              return { ...dataInfo, ...item }
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async submit () {
      const features = this.features.map((f) => ({
        key: f.key,
        value: f.value ? 2 : 1
      }))

      let params = {
        features,
        workspace_id: this.clinic?.id
      }
      this.loading = true
      this.$rf
        .getRequest('AdminRequest')
        .updateWorkspaceSettings(params)
        .then((r) => {
          console.log(r)
          this.$toast.open({
            message: 'Thành công',
            type: 'success'
          })
          window.$('#ModalChangeFeature').modal('hide')
        })
        .finally(() => {
          this.loading = false
        })
    },
    getDate (item) {
      if (!item?.updated_at) return ''
      return window.moment(item.updated_at).format('HH:mm DD/MM/YYYY')
    },
    getUpdatedBy (item) {
      return item.updated_info?.name || item.updated_info?.user_name || ''
    }
  }
}
</script>

<style scoped>
.modal-lg {
  max-width: 800px !important;
}
</style>