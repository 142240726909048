<template>
  <div class="p-3">
    <form @submit.prevent="sendInvites()" method="POST">
      <div class="mb-3">
        <label class="robo-20-500 required" autofocus>{{ $t('blank_state.lbl_org_code') }}</label>
        <input
          v-model="params.code"
          type="text"
          class="form-control mt-2"
          :placeholder="$t('blank_state.input_enter_org_code')"
          autocomplete="off"
        />
      </div>
      <div class="d-flex justify-content-between">
        <button
          type="submit"
          class="btn bg-pri bd-pri text-white"
          @submit.prevent="sendInvites()"
        >{{ $t('blank_state.btn_join_org') }}</button>
        <button type="button" class="btn btn--ghost" @click="closeShow()">{{ $t('button.back') }}</button>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  name: 'CodeInvitesOrg',
  data () {
    return {
      params: {
        type: 'clinic',
        code: ''
      }
    }
  },
  mounted () {
    let self = this
    window.$(document).ready(function () {
      window.$('#modal__join').on('hidden.bs.modal', function () {
        self.params.code = ''
      })
    })
  },
  methods: {
    async sendInvites () {
      try {
        let self = this
        await self.$rf
          .getRequest('AuthRequest')
          .postInvites(self.params)
          .then(() => {
            self.$emit('closeShowInvites')
            this.$toast.open({
              message: 'Gửi thành công',
              type: 'success'
            })
          })
          .catch(e => {
            let code = e.message.substr(e.message.length - 3)
            if (parseInt(code) === 422) {
              this.$toast.open({
                message: this.$t('workspace_setting.lbl_workspace_not_found')[this.$orgModel],
                type: 'warning'
              })
            }
          })
      } catch (e) {}
    },
    closeShow () {
      let self = this
      self.$emit('closeShowInvites')
    }
  }
}
</script>
<style scoped>
</style>
